import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

function CheckPointSlide({ img, heading, checkList }) {
  return (
    <div className="flex flex-wrap justify-between flex-col-reverse md:flex-row  md:mt-[60px]">
      <div className="w-full md:w-1/2">
        <img src={img.src} alt="" width={img.width} height={img.height} />
      </div>
      <div className="w-full md:p-[20px] md:pt-[20px] lg:pt-[60px] md:w-6/12 mt-0 mb-[28px] md:mb-0">
        <h3 className="text-3md md:text-shs font-semibold text-cf-hero max-w-[500px] mb-[32px] font-body">
          {heading}
        </h3>
        <ul className="p-0 mb-[32px] list-none max-w-full">
          {checkList.map(({ id, text }) => (
            <li className="mb-4 last:mb-0 flex" key={id}>
              <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
                <Icon name="tick" width="9.21px" height="6.29px" fill="#fff" />
              </span>
              <div className="font-body !m-0 text-2.5sm md:text-[15px] md:leading-6">
                {text}
              </div>
            </li>
          ))}
        </ul>
        <a
          className="button button-green panel"
          href="https://merchant.cashfree.com/merchants/signup?source-action=easy%20split&action=Sign%20Up&button-id=StartNow_Builtforbusinesses">
          Create Account <span className="chevron" />
        </a>
      </div>
    </div>
  );
}
CheckPointSlide.defaultProps = {
  img: {
    src: '',
    width: '',
    height: '',
  },
  heading: 'Heading',
  checkList: [
    {
      id: 1,
      text: 'Point 1',
    },
    {
      id: 2,
      text: 'Point 2',
    },
  ],
};
CheckPointSlide.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checkList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
};
export default CheckPointSlide;
