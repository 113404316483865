import React, { useCallback, useState } from 'react';
import FAQ from '../FAQ';

function EdTechPanel() {
  const [accordionAnimImage, setAccordionAnimImage] = useState(0);
  const getAccordionAnimImage = useCallback((id) => {
    setAccordionAnimImage(id);
  }, []);
  return (
    <div className="flex flex-nowrap md:flex-wrap justify-start md:mt-[60px] mx-[-20px] md:mx-0">
      <div className="flex-auto w-full md:w-6/12 hidden md:block">
        <div className="relative ">
          <div
            className={`hidden static transform-none mx-auto transition-all duration-300 ease${
              accordionAnimImage === 0 ? ' !block' : ''
            }`}>
            <img
              src="/img/easysplit/edtech-1.svg"
              alt=""
              width="610"
              height="493"
            />
          </div>
          <div
            className={`hidden static transform-none mx-auto transition-all duration-300 ease${
              accordionAnimImage === 1 ? ' !block' : ''
            }`}>
            <img
              src="/img/easysplit/edtech-2.svg"
              alt=""
              width="610"
              height="493"
            />
          </div>
        </div>
      </div>
      <div className="flex-auto px-[20px] w-full md:w-6/12 easysplit-faq">
        <div className="text-3md md:text-lg font-semibold font-body mb-[12px] md:mb-[32px] md:pt-[20px] lg:pt-[60px]">
          Providing educational services online or offline?
        </div>
        <FAQ
          supportLink={false}
          arrow="arrow"
          getActive={getAccordionAnimImage}
          isPayouts
          alwaysOpen
          dataHeading="Providing educational services online or offline?"
          data={[
            {
              id: 0,
              q: (
                <div className="flex items-center">
                  <span className="inline-block w-[32px] h-[32px] mr-3">
                    <img
                      className="w-full h-full"
                      src="/img/icons/purple-bookgear.svg"
                      width="25"
                      height="30"
                      alt=""
                    />
                  </span>
                  Ed-tech platforms
                </div>
              ),
              a: (
                <>
                  <div className="md:pl-[45px] max-w-[90%] text-2sm md:text-md">
                    Split incoming tuition or course fees, deduct your
                    commissions and settle into the accounts of teachers and
                    other service&nbsp;providers
                  </div>
                  <img
                    className="md:hidden mt-6"
                    src="/img/easysplit/edtech-1.svg"
                    alt=""
                    width="610"
                    height="493"
                  />
                </>
              ),
            },
            {
              id: 1,
              q: (
                <div className="flex items-center">
                  <span className="inline-block w-[32px] h-[32px] mr-3">
                    <img
                      className="w-full h-full"
                      src="/img/icons/green-hat.svg"
                      width="32"
                      height="20"
                      alt=""
                    />
                  </span>
                  Educational Institutions
                </div>
              ),
              a: (
                <>
                  <div className="md:pl-[45px] max-w-[90%] text-2sm md:text-md">
                    Split incoming tuition or course fees into your own
                    department accounts such as administration, maintenance,
                    mess, etc without a&nbsp;hassle
                  </div>
                  <img
                    className="md:hidden mt-6"
                    src="/img/easysplit/edtech-2.svg"
                    alt=""
                    width="610"
                    height="493"
                  />
                </>
              ),
            },
          ]}
          style={{
            border: 'none',
            margin: '0',
            padding: '0',
          }}
        />
        <div className="flex flex-wrap md:flex-nowrap justify-start mt-[4px] md:mt-[40px]">
          <div className="w-full pr-2">
            <a
              className="button button-green panel"
              href="https://merchant.cashfree.com/merchants/signup?source-action=PG%20Page&action=Sign%20Up&button-id=StartNow_Hero">
              Create Account <span className="chevron" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EdTechPanel;
