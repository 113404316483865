import React from 'react';
import PropTypes from 'prop-types';
import './GradientTagContainer.scss';

export const ButtonType = {
  account: 'Create Account',
  sales: 'Contact Sales',
  download: 'Download Now',
};

function GradientTagContainer({
  className,
  heading,
  subHeading,
  button,
  link,
  externalLink,
  type,
}) {
  const externalProps = externalLink
    ? {
        target: '_blank',
        rel: 'noreferrer',
      }
    : {};
  return (
    <div
      className={`gtcontainer--${type} relative flex flex-col md:flex-row items-center ${
        type === 'primary' ? 'bg-cf-primary' : 'bg-cf-hero'
      } rounded px-4 md:px-[80px] py-[40px] md:py-[56px] md:mt-[96px] mt-[48px] gradient-saving-account ${className}`}>
      <div className="text-left text-white text-3md font-semibold">
        {heading}
        <p className="text-2.5sm md:text-md font-normal mt-2 md:mt-3 mb-[32px] md:mb-0">
          {subHeading}
        </p>
      </div>
      <div className="flex-1 self-start md:self-auto w-full md:w-auto">
        <a
          className="button button-green  w-full md:w-max pl-[28px] pr-[38px] float-right"
          href={link}
          {...externalProps}>
          {ButtonType[button] || 'Button'} <span className="chevron" />
        </a>
      </div>
    </div>
  );
}
GradientTagContainer.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subHeading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  link: PropTypes.string,
  button: PropTypes.oneOf(['account', 'sales', 'download']),
  className: PropTypes.string,
  externalLink: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'hero']),
};
GradientTagContainer.defaultProps = {
  heading: '',
  subHeading: '',
  link: '!#',
  button: 'account',
  className: '',
  externalLink: false,
  type: 'primary',
};
export default GradientTagContainer;
