import React from 'react';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import Scrollspy from '../components/Scrollspy';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import SliderEasySplit from '../components/SliderEasySplit';
import CheckPointSlide from '../components/SliderEasySplit/CheckPointSlide';
import EdTechPanel from '../components/SliderEasySplit/EdTechPanel';
import GradientTagContainer from '../components/common/GradientTagContainer/GradientTagContainer';
import EnterprisePricing from '../components/common/EnterprisePricing/EnterprisePricing';
import OtherProducts from '../components/OtherProducts/OtherProducts';
import '../styles/easy-split.scss';

const checkList = [
  { id: 0, listItem: 'Move money faster' },
  { id: 1, listItem: 'Simplify refunds' },
  { id: 2, listItem: 'Automate reconciliation' },
];

const tabData = [
  {
    key: 0,
    heading: 'Marketplace Aggregators',
    content: (
      <CheckPointSlide
        img={{
          src: '/img/easysplit/marketplace.svg',
          width: '610',
          height: '493',
        }}
        heading="Connecting customers with sellers/service providers on your platform?"
        checkList={[
          {
            id: 0,
            text: (
              <>
                <h2 className="inline font-body">
                  Split incoming customer payments
                </h2>{' '}
                between multiple sellers/service providers
              </>
            ),
          },
          {
            id: 1,
            text:
              'Deduct your commissions as per the defined amount/percentage, time, and stakeholders to settle',
          },
        ]}
      />
    ),
  },
  {
    key: 1,
    heading: 'Financial Services',
    content: (
      <CheckPointSlide
        img={{
          src: '/img/easysplit/financial.svg',
          width: '610',
          height: '493',
        }}
        heading="Connecting customers with AMCs or insurance providers on your platform?"
        checkList={[
          {
            id: 0,
            text:
              'Enable customers to make one single payment & split it into the bank accounts of multiple AMCs or insurance providers at predefined proportions',
          },
          {
            id: 1,
            text: 'Automate instant/same-day settlements to all parties',
          },
        ]}
      />
    ),
  },
  {
    key: 2,
    heading: 'Ed-Tech and Education',
    content: <EdTechPanel />,
  },
  {
    key: 3,
    heading: 'Franchise-based businesses',
    content: (
      <CheckPointSlide
        img={{
          src: '/img/easysplit/franchise.svg',
          width: '610',
          height: '493',
        }}
        heading="Are you a business with multiple branches or franchises?"
        checkList={[
          {
            id: 0,
            text:
              'Bring an end to your struggle of managing and collecting funds from your individual branches',
          },
          {
            id: 1,
            text:
              'Collect commissions for all your branches directly into your bank account with a defined split logic on an order level',
          },
        ]}
      />
    ),
  },
];
const easySplitData = [
  {
    id: 0,
    icon: '/img/icons/purple-up-arrow.svg',
    title: '15% higher success rate during checkout',
    description: (
      <p className="text-2sm md:text-md">
        With 3 times auto-retry option, saved card option, smart routing among
        multiple banks and networks, and optimized integration flows for mobile
        and web, achieve higher uptime and success rates every time.
      </p>
    ),
  },
  {
    id: 1,
    icon: '/img/icons/green-webhook.svg',
    title: 'Webhook-based reconciliation',
    description: (
      <p className="text-2sm md:text-md">
        Get notified on transaction status - success, pending, etc and reconcile
        all payments, refunds, and settlements in real-time using webhooks.
      </p>
    ),
  },
  {
    id: 2,
    icon: '/img/icons/three-arrow.svg',
    title: 'Flexible refund management',
    description: (
      <div className="text-2sm md:text-md">
        Managing customer refunds when there is a single invoice and multiple
        vendors have never been this easy! Specify the amount to be split
        between you & the vendor. And Easy Split will automatically process the
        refunds without any hassle.
        <div className="bg-cf-primary px-[24px] py-[12px] text-base text-white rounded relative mt-4">
          Automate refunds adjustment even after vendor settlements.
          <div className="absolute top-[-2.6px] left-[-2.7px]">
            <img
              src="/img/easysplit/ribbon-more.svg"
              alt="ribbion"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 3,
    icon: '/img/icons/rupee-bag.svg',
    title: 'Hassle-Free Month-End Finance Closures',
    description: (
      <p className="text-2sm md:text-md">
        Eliminate all the manual work of figuring out whom to pay and when & let
        your operations and finance team be at ease during monthly/yearly
        finance closures.
      </p>
    ),
  },
  {
    id: 4,
    icon: '/img/icons/checklist.svg',
    title: 'Comprehensive reporting',
    description: (
      <p className="text-2sm md:text-md">
        Get detailed reports - transactions, settlements, refunds, and vendor
        payments and adjustments. With centralized reporting, stay on top of
        your business finances always.
      </p>
    ),
  },
];

const EasySplit = (props) => {
  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=easy%20split&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=easy%20split&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Easy Split: Collect & Split Settlements For All Incoming Funds"
        description="Collect customer payments via any digital method, deduct your commissions & split settlements between multiple stakeholders even on an order level."
        url="https://www.cashfree.com/split-settlement/"
        keywords={[
          'split Payment',
          'split payment gateway',
          'split payment app',
          'split payment online',
          'easy split payment',
          'Split Accounts',
          'Money split',
          'Multi payment gateway',
          'Split Bills online',
          'Spilt Money',
        ]}
      />

      <div className="section-padding pt-[110px] md:pt-[140px] easysplit-hero">
        <div className="container position-relative">
          <div className="flex flex-wrap justify-start lg:justify-between">
            <div className="content w-full lg:w-1/2 md:pr-10">
              <h2 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                EASY SPLIT
              </h2>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[680px] lg:w-[110%] text-white font-heading">
                Collect & <h1 className="inline">split settlements</h1> for all
                incoming funds with multiple parties
              </div>
              <div className="mb-[30px] max-w-[547px] text-[16px] leading-[24px] md:text-2.5md text-white font-body">
                With Easy Split, collect payments from customers via any digital
                method, deduct commissions, and{' '}
                <h2 className="font-body inline">split payments</h2> to vendors,
                partners & your own bank&nbsp;accounts.
              </div>
              <ul className="p-0 mt-6 md:mt-8 mb-4 md:mb-9 list-none max-w-full flex flex-row flex-wrap md:flex-nowrap md:items-center justify-start checklist">
                {checkList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] last:mb-[15px] md:last:mb-[15px] mr-[29px] lg:mr-4 xl:mr-[24px] last:mr-0 flex text-white"
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-cf-primary self-center">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    {id === 2 ? (
                      <h2 className="font-body !m-0 text-2.5sm md:text-md">
                        {listItem}
                      </h2>
                    ) : (
                      <div className="font-body !m-0 text-2.5sm md:text-md">
                        {listItem}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start">
                <a
                  className="button button-green w-full  md:w-auto mb-4 md:mb-0 mr-0 md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=easy%20split&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto"
                  href="https://www.cashfree.com/contact-sales?source-action=easy%20split&action=Contact%20Sales&button-id=ContactSales_Hero">
                  Contact Sales <span className="chevron !mb-0" />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:mt-[-10%] bg-video relative order-3 lg:order-2">
              <video
                autoPlay
                muted
                playsInline
                loop
                className="lg:max-w-[110%] inset-video  aspect-square relative z-10 chrome-video"
                width="800"
                height="700">
                <source
                  src="/img/easysplit/hero-video.webm"
                  type="video/webm"
                />
                <source
                  src="/img/easysplit/hero-video_H264.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                autoPlay
                muted
                playsInline
                loop
                className="lg:max-w-[110%] inset-video hidden safari-video aspect-square relative z-10"
                width="800"
                height="700">
                <source
                  src="/img/easysplit/hero-video_H264.mp4"
                  type="video/mp4"
                />
              </video>
              {/* <video
              autoPlay
              muted
              playsInline
              loop
              className="inset-video block aspect-square"
              width="800"
              height="700">
              <source src="/img/easysplit/hero-video.mp4" type="video/mp4" />
            </video> */}
            </div>
            <div className="w-full mt-8 md:mt-12 xl:mt-[10px] logos order-2 lg:order-3">
              <div className="relative overflow-hidden">
                <div className="row justify-between max-w-[1000px] mx-auto marquee marquee-3 marqee-mob">
                  <span>
                    <img
                      src="/img/nykaa.svg"
                      alt="nykaa"
                      width="122"
                      height="40"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/swiggy.png"
                      width="178"
                      height="54"
                      style={{ maxWidth: 89 }}
                      alt="swiggy"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/krazybee.png"
                      alt="krazybee"
                      width="198"
                      height="66"
                      style={{ maxWidth: 100 }}
                    />
                  </span>
                  <span>
                    <img
                      src="/img/adda.png"
                      alt="adda"
                      width="188"
                      height="58"
                    />
                  </span>
                  <span>
                    <img
                      src="/img/ima.png"
                      alt="Indian Medical Association"
                      width="41"
                      height="43"
                      style={{ maxWidth: 45 }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Scrollspy
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/easy-split.svg"
              alt="easy-split-icon"
              width="25px"
            />
            <span>Easy Split</span>
          </div>
        }>
        <section
          data-spy-title="Features"
          data-spy-id="features"
          className="py-[48px] md:py-[128px]">
          <div className="container">
            {/* <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
            EASY SPLIT
          </p> */}
            <h2 className="text-lg md:text-xl font-semibold text-cf-hero max-w-[510px] mb-[20px]">
              Built for businesses with complex payment outflows
            </h2>
            <Tabs
              data={tabData}
              dropDown
              dropDownFontSize="text-[16px]"
              timerAutoPlay
            />
            <GradientTagContainer
              heading="Got any other unique use case?"
              subHeading="Do not worry, we have got you covered! Talk to our team."
              link="https://www.cashfree.com/contact-sales?source-action=easy%20split&action=Contact%20Sales&button-id=ContactSales_Talktoourteam"
              button="sales"
            />
          </div>
        </section>

        <section
          data-spy-title="Integration"
          data-spy-id="integration"
          className="pt-[48px] pb-[60px] md:py-[96px] bg-cf-light-grey overflow-hidden">
          <div className="container">
            <h2 className="text-lg md:text-xl text-center font-semibold text-cf-hero max-w-[610px] mx-auto mb-8 md:mb-[52px]">
              Here is why leading businesses use&nbsp;Easy&nbsp;Split
            </h2>
            <div className="flex flex-col">
              <SliderEasySplit data={easySplitData} />
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-center mb-0  mt-7 md:mt-[35px]">
                <a
                  className="button button-green w-full md:w-auto mb-[16px] md:mb-0 md:mr-[16px]"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=easy%20split&action=Sign%20Up&button-id=StartNow_Hereiswhyleading">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-light no-shadow text-cf-hero border-cf-stroke w-full md:w-auto"
                  href="https://docs.cashfree.com/docs/easy-split-introduction">
                  View Developer Docs <span className="chevron dark !mb-0" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          data-spy-title="Pricing"
          data-spy-id="pricing"
          className="p-section-2">
          <div className="container pricing-section-easysplit">
            <div className="flex flex-wrap items-center justify-between mx-[-15px]">
              <div className="w-full p-[15px] xl:w-4/12">
                <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                  PRICING
                </p>
                <h2 className="text-cf-hero text-4md md:text-xl max-w-[373px] font-semibold">
                  Exclusive Introductory Pricing
                </h2>
                <p className="mt-[20px] text-base max-w-[400px]">
                  Sign up for Easy Split Payments today and manage your vendor
                  payments at the lowest cost!
                </p>
              </div>
              <div className="w-full px-[15px] xl:w-7/12">
                <div className="flex flex-col justify-center md:flex-row">
                  <div className="w-full xl:max-w-[334px] md:self-center mb-[4px] md:mb-0">
                    <div className="p-4 md:p-6 starts-card rounded-[4px]">
                      <div className="text-md md:text-2sm text-cf-hero opacity-50 font-medium">
                        Just
                      </div>
                      <div className="inline text-cf-primary text-2xl starts-at relative font-medium">
                        0.1%
                      </div>
                      <span className="inline-block ml-[8px] text-cf-faded-black text-base font-semibold line-through decoration-2">
                        0.25%
                      </span>
                      <p className="mb-[40px] text-2.5sm">
                        on order value charged for every split.
                      </p>
                      <a
                        className="button button-green w-full"
                        href="https://merchant.cashfree.com/merchants/signup?source-action=easy%20split&action=Sign%20Up&button-id=StartNow_Pricing">
                        Create Account <span className="chevron" />
                      </a>
                      <small className="italic text-tiny pt-3  pr-1 font-normal  block md:hidden">
                        * Standard collection charges apply
                      </small>
                    </div>
                    <small className="italic text-tiny pt-2  pr-1 font-normal hidden md:block">
                      * Standard collection charges apply
                    </small>
                  </div>
                  <EnterprisePricing link="https://www.cashfree.com/contact-sales?source-action=easy%20split&action=Contact%20Sales&button-id=ContactSales_Pricing" />
                </div>
              </div>
            </div>
          </div>

          <div className="container general-faqs md:mt-[86px]">
            <FAQ
              supportLinkHref="https://www.cashfree.com/help/hc"
              open="0"
              data={[
                {
                  id: 0,
                  q: 'What exactly is Easy Split?',
                  a: (
                    <p className="faq-p">
                      Easy Split by Cashfree is a split payment gateway for
                      marketplaces that helps merchants accept payments from
                      customers, deduct commissions, and automatically settle
                      payments to the vendors. Easy Split also takes care of
                      customer refund processing and subsequent adjustment in
                      vendor settlements.
                    </p>
                  ),
                },
                {
                  id: 1,
                  q:
                    'How is vendor settlement managed when there are multiple vendors linked to one transaction?',
                  a: (
                    <p className="faq-p">
                      At the time of adding a transaction, you can assign the
                      appropriate numeric commission amount against each vendor
                      that you add with Cashfree.
                    </p>
                  ),
                },
                {
                  id: 2,
                  q:
                    'Do I have to use Cashfree payment gateway to use Easy Split?',
                  a: (
                    <p className="faq-p">
                      Yes, when you create an Easy Split account, Cashfree
                      Payment Gateway will be automatically activated for your
                      account. All payments collected via the payment gateway
                      are automatically split using the integrated payment flow
                      including refund processing.
                    </p>
                  ),
                },
                {
                  id: 3,
                  q:
                    'Can I use Cashfree Payment Links to use Easy Split instead of a payment gateway?',
                  a: (
                    <p className="faq-p">
                      Yes, you cannot only use payment links but also payment
                      forms and subscriptions to use easy split.
                    </p>
                  ),
                },
                {
                  id: 4,
                  q:
                    'How can I define vendor commission structure to split payments?',
                  a: (
                    <p className="faq-p">
                      You can specify an absolute value for the vendor&apos;s
                      share in the overall transaction value. The remaining
                      amount (Order Amount - Total Vendor’s share) is considered
                      your share.
                    </p>
                  ),
                },
                {
                  id: 5,
                  q:
                    'How can I process refunds and manage adjustments with vendors?',
                  a: (
                    <p className="faq-p">
                      You can provide a split while initiating refunds from API
                      or Dashboard. As per your inputs, the corresponding
                      vendor’s balance is debited. This will help take care of
                      any receivables from your vendor.
                    </p>
                  ),
                },
                {
                  id: 6,
                  q: 'Can I change the vendor assigned to a transaction?',
                  a: (
                    <p className="faq-p">
                      If the transaction has been marked successful, you will
                      not be able to change the vendor associated with the
                      specific transaction.
                    </p>
                  ),
                },
                {
                  id: 7,
                  q:
                    'Can I have different settlement schedules for different vendors?',
                  a: (
                    <p className="faq-p">
                      Yes, this is possible by modifying the vendor’s settlement
                      preferences. By default, pending vendor balances get
                      settled automatically as per the settlement schedule
                      defined by you.
                    </p>
                  ),
                },
                {
                  id: 8,
                  q: 'How much time does it take to add a vendor?',
                  a: (
                    <p className="faq-p">
                      Using Easy Split, you can add any number of vendors
                      instantly. Once a vendor is added, Easy Split
                      automatically makes adjustments and settles amounts as per
                      the defined commission structure and settlement cycle.
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </section>
      </Scrollspy>

      <section className="bg-cf-light-grey">
        <OtherProducts
          data={[
            {
              id: 0,
              title: 'Payouts',
              body:
                'Make payouts to any bank account / UPI ID / card/ AmazonPay / Paytm instantly even on a bank holiday.',
              url: 'https://www.cashfree.com/payouts/',
              iconname: '/img/icons/payouts-green.svg',
            },
            {
              id: 1,
              title: 'Payment Links',
              body: (
                <>
                  No-code payment links to collect payments over WhatsApp, SMS,
                  Facebook, Twitter and other&nbsp;channels.
                </>
              ),
              url: 'https://www.cashfree.com/payment-links',
              iconname: '/img/icons/payment-links.svg',
            },
            {
              id: 2,
              title: 'Cashgram',
              body:
                'Payout links to send money without collecting receiver bank account details or UPI details.',
              url: 'https://www.cashfree.com/cashgram/',
              iconname: '/img/icons/cashgram.svg',
            },
            {
              id: 3,
              title: 'Instant Settlements',
              body:
                'Get access Get access to your payment gateway collections within 15 minutes of payment capture, avoid dependency on credit lines.',
              url: 'https://www.cashfree.com/instant-settlements/',
              iconname: '/img/icons/yellow-zap.svg',
            },
          ]}
          heading="Explore other products"
        />
      </section>

      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=easy%20split&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=easy%20split&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default EasySplit;
