import React from 'react';
import PropTypes from 'prop-types';

function SliderButton(props) {
  const { className, onClick, left } = props;
  return (
    <button
      type="button"
      className={`${className} slider-btn shadow hover:shadow-md`}
      onClick={onClick}>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        className={left ? 'left' : 'right'}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.44531 10.6667L1.44531 6.2223L6.44531 1.77786"
          stroke="#240253"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
SliderButton.propTypes = {
  left: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};
SliderButton.defaultProps = {
  left: true,
  className: '',
  style: {},
  onClick: null,
};

export default SliderButton;
