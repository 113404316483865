// import React, { useRef, useEffect } from 'react';
import React from 'react';
// import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import SliderButton from './SliderButton';
import './SliderEasySplit.scss';

function SliderEasySplit({ data, settings }) {
  // const slider = useRef();
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.9,
  // });
  // useEffect(() => {
  //   if (inView) {
  //     if (slider.current) {
  //       slider.current.slickGoTo(0, false);
  //     }
  //   }
  // }, [inView]);
  return (
    <div className="easy-split mb-[13px] md:pb-0">
      <Slider {...settings}>
        {data.map((d) => (
          <div
            key={d.id}
            className="w-full md:w-4/12 px-[8px] md:px-[19px] mb-10">
            <div className="p-[24px] bg-white h-full relative min-h-[400px] sm:min-h-[350px] md:min-h-[390px] lg:min-h-[370px]">
              <div className="w-[30px] h-[30px] md:w-[40px] md:h-[40px]">
                <img
                  src={d.icon}
                  alt=""
                  className="w-[30px] h-[30px] md:w-[40px] md:h-[40px]"
                />
              </div>
              <h2 className="mt-[16px] mb-[8px] font-body font-semibold">
                {d.title}
              </h2>
              {d.description}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

SliderEasySplit.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.node,
    }),
  ),
  settings: PropTypes.shape({}),
};

SliderEasySplit.defaultProps = {
  data: [],
  settings: {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true,
    slidesToScroll: 2,
    // initialSlide: 1,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
    ],
  },
};

export default SliderEasySplit;
